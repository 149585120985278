import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { LoginService } from '../../services/login.service';
import { SnackbarService } from '../../shared/services/snackbar.service';
import { PASSWORD_REGEX } from '../../shared/pattern';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { LoginWrapperComponent } from '../login-wrapper/login-wrapper.component';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';

@Component({
  selector: 'app-forgot-password',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    LoginWrapperComponent,
    ButtonModule,
    InputTextModule,
  ],
  templateUrl: './forgot-password.component.html',
  styleUrl: './forgot-password.component.scss',
})
export class ForgotPasswordComponent implements OnInit {
  showDropdown: boolean = false;
  // email = new FormControl(null, [
  //   Validators.required,
  //   Validators.email,
  //   Validators.maxLength(360),
  // ]);
  otp = new FormControl({ value: null, disabled: false });
  currentStep = 'step1';
  newPasswordForm: FormGroup = this.fb.group({});
  isSubmitted: boolean = false;
  passwordVisible: boolean = false;
  confirmPasswordVisible: boolean = false;
  loginForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private loginService: LoginService,
    private toaster: SnackbarService
  ) {
    this.loginForm = this.fb.group({
      email: [
        '',
        [Validators.required, this.emailValidator(), Validators.maxLength(360)],
      ],
    });
  }
  ngOnInit(): void {
    this.initChangePasswordForm();
  }

  private initChangePasswordForm() {
    this.newPasswordForm = this.fb.group(
      {
        newPassword: [
          '',
          [
            Validators.required,
            Validators.pattern(PASSWORD_REGEX),
            Validators.maxLength(24),
            Validators.minLength(8),
          ],
        ],
        confirmNewPassword: ['', [Validators.required]],
      },
      { validator: this.passwordMatchValidator }
    );
  }

  private passwordMatchValidator(frm: FormGroup) {
    return frm.controls['newPassword'].value ===
      frm.controls['confirmNewPassword'].value
      ? null
      : { mismatch: true };
  }

  resetPassword() {
    let userId = this.loginForm.controls['email'].value;
    // if (this.email.valid) {
    this.loginService.forgotPassword(userId).subscribe(
      (data: any) => {
        if (data != null && data != undefined && data != '' && data.success) {
          if (data.success) {
            console.log(data.message);
            this.toaster.success(data.message, '', 3000);
            // this.email.reset();
            this.loginForm.reset();
            this.currentStep = 'step2';
          } else {
            this.toaster.error(data.message, '', 3000);
            // this.email.reset();
            this.loginForm.reset();
          }
        } else {
          if (data.message == 'Not allowed to reset password for SSO user') {
            this.toaster.error(
              'Please use your Identity Provider (like Azure) to change the password',
              '',
              3000
            );
          } else {
            this.toaster.error(
              // 'Activation mail will been sent to ' +
              //   userId +
              //   ' if a valid account exists',
              'Activation mail will been sent to registered email id. if a valid account exists',
              '',
              3000
            );
            this.currentStep = 'step2';
          }
          // this.email.reset();
          this.loginForm.reset();
        }
      },
      (err) => {
        console.log(err);
        this.toaster.error(
          'something went wrong!, UserId is not valid',
          '',
          3000
        );
      }
    );
    // }
  }

  verifyOtp() {
    if (this.otp.valid) {
      this.currentStep = 'step3';
    }
  }

  changePassword() {
    if (this.newPasswordForm.valid) {
      this.currentStep = 'step4';
    }
  }
  navigateToLogin() {
    this.router.navigate([
      environment.routePrefix
        ? '/' + environment.routePrefix + '/auth/login'
        : '/auth/login',
    ]);
  }

  checkError(field: string) {
    if (!this.loginForm.controls[field].touched) {
      return '';
    }

    if (field === 'email') {
      if (this.loginForm.controls[field].hasError('required')) {
        return 'User ID is required';
      } else if (this.loginForm.controls[field].hasError('email')) {
        return 'Please enter a valid User ID';
      } else if (this.loginForm.controls[field].hasError('maxlength')) {
        return 'User ID cannot exceed 360 characters';
      } else {
        return '';
      }
    } else {
      return '';
    }
  }

  emailValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const email = control.value;
      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      const specialCharacterPattern = /^[^!#$%^&*()+=\[\]\';,\/{}|":<>?\\`~]*$/;

      if (!emailPattern.test(email)) {
        return { email: true };
      }
      if (!specialCharacterPattern.test(email)) {
        return { invalidCharacters: true };
      }
      return null;
    };
  }
}
