<app-login-wrapper [page]="'otp'">
    <!-- <div class="col-8 align-self-strech"> -->
        <p-inputOtp [formControl]="otp" [integerOnly]="true" [length]="6"/>
        <div class="error-text py-3" *ngIf="otp.errors && otp.errors['required']">
            Otp is required.
        </div>
        
        <p-button
        label="Submit"
        pRipple
        label="Verify Code"
        (click)="verifyOtp()"
      />
      <h4 class="type link" style="text-decoration: none;cursor: pointer; font-weight: bold;margin-top: 10px;" (click)="navigateToLogin()">Go Back To Login</h4>
    <!-- </div> -->
  </app-login-wrapper>