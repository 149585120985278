import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { GenericService } from '../../shared/genric-service/generic.service';
import { LoginService } from '../../services/login.service';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { Utils } from '../../shared/utils';
import { SnackbarService } from '../../shared/services/snackbar.service';
import { navItems } from '../../constants/nav-item';
import { ButtonModule } from 'primeng/button';
import { LoginWrapperComponent } from '../login-wrapper/login-wrapper.component';
import { InputOtpModule } from 'primeng/inputotp';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-two-factor-verification',
  templateUrl: './two-factor-verification.component.html',
  styleUrl: './two-factor-verification.component.scss',
  standalone: true,
  imports: [
    LoginWrapperComponent,
    InputOtpModule,
    CommonModule,
    ButtonModule,
    ReactiveFormsModule,
  ],
})
export class TwoFactorVerificationComponent {
  otp = new FormControl({ value: null, disabled: false }, Validators.required);
  // privileges: any;
  user: any;
  singleBu: boolean = false;
  filteredItems: any[] = [];
  // @Input() email: any;
  // @Input() server: any;
  isPageLoading: boolean = false;
  navItems: any[] = [];
  @Output() change2FA = new EventEmitter<any>();
  constructor(
    private genericService: GenericService,
    private router: Router,
    private loginService: LoginService,
    private utils: Utils,
    private toaster: SnackbarService
  ) {}
  //  async verifyOtp() {
  //   if (this.otp.valid) {
  //     this.singleBu = false;
  //     var totpForm: any = {};
  //     totpForm['totp'] = this.otp.value;
  //     var semiToken = localStorage.getItem('token')!;

  //     this.loginService
  //       .userTotpVerification(this.otp.value)
  //       .subscribe((data) => {
  //         if (data.success) {
  //           // this.privileges = JSON.stringify(data.data.user.userPrivileges);
  //           this.user = data.data.user;
  //           if (data.data?.token != null) {
  //             // send darkmode to other components

  //             localStorage.removeItem('loginUserName');
  //             localStorage.removeItem('loginUserPassword');
  //             localStorage.removeItem('loginUserRecaptcha');
  //             localStorage.removeItem('token');
  //             localStorage.setItem('email', this.user.userName);
  //             localStorage.setItem('loggedInUserId', this.user.id);
  //             localStorage.setItem('loggedInUserName', this.user.userName);
  //             localStorage.setItem('name', this.user.firstName);
  //             localStorage.setItem('UserRoles', this.user.roles);
  //             localStorage.setItem('userData', JSON.stringify(this.user));
  //             this.loginService.setUserLoggedIn();
  //             localStorage.setItem('token', data.data.token);
  //             let permissions = await this.getAllPermissions(this.user.id);

  //             localStorage.setItem('permissions', JSON.stringify(permissions));
  //             // localStorage.setItem('privileges', this.privileges);

  //             let api = '/identity/users/license';
  //             this.genericService.getRequest(api).subscribe((data: any) => {
  //               if (data.success) {
  //                 this.singleBu = data.data.singleBu;
  //                 localStorage.setItem('singleBu', this.singleBu.toString());

  //                 // this.getUserDetailsById(data.data.vulnMgmt, data.data.complianceMgmt, data.data.vendorRiskMgmt, data.data.asm, data.data.internalAudit, data.data.inboundAudit);

  //                 localStorage.setItem(
  //                   'complianceMgmt',
  //                   JSON.stringify(data.data.complianceMgmt)
  //                 );
  //                 localStorage.setItem(
  //                   'internalAudit',
  //                   JSON.stringify(data.data.internalAudit)
  //                 );
  //                 localStorage.setItem(
  //                   'inboundAudit',
  //                   JSON.stringify(data.data.inboundAudit)
  //                 );
  //                 localStorage.setItem(
  //                   'vendorRiskMgmt',
  //                   JSON.stringify(data.data.vendorRiskMgmt)
  //                 );
  //                 localStorage.setItem('asm', JSON.stringify(data.data.asm));
  //                 localStorage.setItem(
  //                   'vulnMgmt',
  //                   JSON.stringify(data.data.vulnMgmt)
  //                 );
  //                 localStorage.setItem(
  //                   'subscription',
  //                   JSON.stringify(data.data.subscription)
  //                 );

  //                 this.getDashboardToken();
  //               }
  //               if (localStorage.getItem('UserRoles') == 'SZE_ADMIN') {
  //                 this.router.navigate([
  //                   environment.routePrefix
  //                     ? '/' + environment.routePrefix + '/tenant'
  //                     : '/tenant',
  //                 ]);
  //               } else {
  //                 this.router.navigate([
  //                   this.utils.getDynamicUrlBasisOnPrivileges(),
  //                 ]);
  //                 this.utils.routeTo(true);
  //               }
  //             });
  //           }
  //         } else {
  //           this.toaster.error(data.message, '', 3000);
  //         }
  //       });
  //   }
  // }

  async verifyOtp() {
    if (this.otp.valid) {
      this.singleBu = false;
      // const totpForm: any = { totp: this.otp.value };
      // const semiToken = localStorage.getItem('token')!;

      try {
        const data = await this.loginService
          .userTotpVerification(this.otp.value)
          .toPromise();
        if (data.success) {
          this.user = data.data.user;
          if (data.data?.token != null) {
            localStorage.removeItem('loginUserName');
            localStorage.removeItem('loginUserPassword');
            localStorage.removeItem('loginUserRecaptcha');
            localStorage.removeItem('token');
            localStorage.setItem('email', this.user.userName);
            localStorage.setItem('loggedInUserId', this.user.id);
            localStorage.setItem('loggedInUserName', this.user.userName);
            localStorage.setItem('name', this.user.firstName);
            localStorage.setItem('UserRoles', this.user.roles);
            localStorage.setItem('userData', JSON.stringify(this.user));
            this.loginService.setUserLoggedIn();
            localStorage.setItem('token', data.data.token);

            const permissions = await this.getAllPermissions(this.user.id);
            localStorage.setItem('permissions', JSON.stringify(permissions));

            const api = '/identity/users/license';
            const licenseData = await this.genericService
              .getRequest(api)
              .toPromise();
            if (licenseData.success) {
              this.singleBu = licenseData.data.singleBu;
              localStorage.setItem('singleBu', this.singleBu.toString());

              localStorage.setItem(
                'complianceMgmt',
                JSON.stringify(licenseData.data.complianceMgmt)
              );
              localStorage.setItem(
                'internalAudit',
                JSON.stringify(licenseData.data.internalAudit)
              );
              localStorage.setItem(
                'inboundAudit',
                JSON.stringify(licenseData.data.inboundAudit)
              );
              localStorage.setItem(
                'vendorRiskMgmt',
                JSON.stringify(licenseData.data.vendorRiskMgmt)
              );
              localStorage.setItem('asm', JSON.stringify(licenseData.data.asm));
              localStorage.setItem(
                'vulnMgmt',
                JSON.stringify(licenseData.data.vulnMgmt)
              );
              localStorage.setItem(
                'subscription',
                JSON.stringify(licenseData.data.subscription)
              );

              // this.getDashboardToken();
            }

            if (localStorage.getItem('UserRoles') === 'SZE_ADMIN') {
              this.router.navigate([
                environment.routePrefix
                  ? '/' + environment.routePrefix + '/tenant'
                  : '/tenant',
              ]);
            } else {
              this.router.navigate([
                this.utils.getDynamicUrlBasisOnPrivileges(),
              ]);
              this.utils.routeTo(true);
            }
          }
        } else {
          this.toaster.error(data.message, '', 3000);
        }
      } catch (error) {
        console.error('Verification error:', error);
        this.toaster.error('An error occurred during verification.', '', 3000);
      }
    }
  }

  // getDashboardToken() {
  //   if (localStorage.getItem('loggedInUserId')) {
  //     let api =
  //       '/identity/users/' +
  //       localStorage.getItem('loggedInUserId')?.toString() +
  //       '/spaces/search/';
  //     this.genericService.postRequest(api, {}).subscribe((data: any) => {
  //       this.isPageLoading = false;
  //       if (data.success) {
  //         var dashboardTokens = data.data;

  //         if (dashboardTokens.CMToken_1) {
  //           localStorage.setItem('cm_dashboard_1', dashboardTokens.CMToken_1);
  //         }
  //         if (dashboardTokens.CMToken_2) {
  //           localStorage.setItem('cm_dashboard_2', dashboardTokens.CMToken_2);
  //         }

  //         if (dashboardTokens.RMToken_1) {
  //           localStorage.setItem('rm_dashboard_1', dashboardTokens.RMToken_1);
  //         }

  //         if (dashboardTokens.RMToken_2) {
  //           localStorage.setItem('rm_dashboard_2', dashboardTokens.RMToken_2);
  //         }
  //       }
  //     });
  //   }
  // }

  async getAllPermissions(userId: string): Promise<any[]> {
    let api = `/identity/users/${userId}/permissions/search/`;
    return new Promise((resolve, reject) => {
      this.genericService.getRequest(api).subscribe(
        (data: any) => {
          if (data.success) {
            resolve(data.data.userPermissions);
          } else {
            resolve([]);
          }
        },
        (error) => {
          reject(error);
        }
      );
    });
  }

  // getUserDetailsById(vulnMgmt: any, complianceMgmt: any, vendorRiskMgmt: any, asm: any, internalAudit: any, inboundAudit: any) {

  //   const userRolesData = localStorage['UserRoles'];
  //   // var privilegesList = JSON.parse(localStorage['privileges']);

  //   if (userRolesData == this.utils.UserRole.SZE_ADMIN) {
  //     this.filteredItems = [];
  //     for (var privilege of privilegesList) {
  //       if (privilege.mainModule == 'Customer Mgmt') {
  //         for (let child of navItems) {
  //           if (child.name == 'Customer Mgmt') {
  //             this.filteredItems.push(child);
  //           }
  //           if (child.name == 'Sensors') {
  //             this.filteredItems.push(child);
  //           }
  //           if (child.name == 'Settings') {
  //             this.filteredItems.push(child);
  //           }
  //         }
  //       }
  //     }
  //     this.navItems = this.filteredItems;

  //   } else if (userRolesData !== this.utils.UserRole.SZE_ADMIN && (privilegesList?.length > 0)) {
  //     this.filteredItems = [];

  //     var userObjData = JSON.parse(localStorage.getItem('userData')!);

  //     for (let item of navItems) {
  //       var childItem = [];

  //       if ((item.name != 'Customer Mgmt' && item.name != 'Sensors' && item.name != 'Settings') && (vulnMgmt || complianceMgmt || vendorRiskMgmt || asm)) {

  //         for (let child of item.children!) {

  //           for (var privilege of privilegesList) {

  //             if (userObjData.roles != 'NONE') {

  //               // WHEN ONLY VULN MGMT IS TRUE
  //               if (vulnMgmt &&
  //                 (privilege.mainModule == 'Vuln Mgmt' || privilege.mainModule == 'Administration') &&
  //                 (item.name == 'Vuln Mgmt' || item.name == 'Administration') &&
  //                 (privilege.subModule == child.name &&
  //                   privilege.subModule != 'Policy Bundles' &&
  //                   privilege.subModule != 'Compliances' &&
  //                   privilege.subModule != 'Audit Rules' &&
  //                   privilege.status == 'true' &&
  //                   privilege.permission != 'NONE')) {

  //                 if (childItem.indexOf(child) !== -1) {
  //                   continue;
  //                 }
  //                 if (!(userRolesData == 'USER' && item.name == 'Administration' && child.name == 'Users')) {
  //                   childItem.push(child);
  //                 }

  //                 // Push 'Reports' page to 'Vuln Mgmt' without checking any conditions
  //                 if (item.name == 'Vuln Mgmt' && child.name == 'Reports') {
  //                   if (childItem.indexOf(child) === -1) {
  //                     childItem.push(child);
  //                   }
  //                 }
  //               }

  //               // WHEN ONLY COMP MGMT IS TRUE
  //               if (complianceMgmt &&
  //                 (privilege.mainModule == 'Compliance Mgmt' || privilege.mainModule == 'Administration') &&
  //                 (item.name == 'Compliance Mgmt' || item.name == 'Administration') && (
  //                   (privilege.subModule == 'Internal Audits' ? (internalAudit && privilege.subModule == child.name) :
  //                     privilege.subModule == 'Inbound Audits' ? (inboundAudit && privilege.subModule == child.name) : privilege.subModule == child.name
  //                   ) &&
  //                   privilege.subModule != 'Assets' &&
  //                   privilege.subModule != 'Sensors' &&
  //                   privilege.subModule != 'Remediation' &&
  //                   privilege.status == 'true' &&
  //                   privilege.permission != 'NONE')) {

  //                 if (childItem.indexOf(child) !== -1) {
  //                   continue;
  //                 }
  //                 if (!(userRolesData == 'USER' && item.name == 'Administration' && child.name == 'Users')) {
  //                   childItem.push(child);
  //                 }
  //                 // Push 'Reports' page to 'Compliance Mgmt' without checking any conditions
  //                 if (item.name == 'Compliance Mgmt' && child.name == 'Reports') {
  //                   if (childItem.indexOf(child) === -1) {
  //                     childItem.push(child);
  //                   }
  //                 }

  //               }

  //               // WHEN ONLY VENDOR RISK MGMT IS TRUE
  //               if (vendorRiskMgmt &&
  //                 (privilege.mainModule == 'Vendor Risk Mgmt' || privilege.mainModule == 'Administration') &&
  //                 (item.name == 'Vendor Risk Mgmt' || item.name == 'Administration') && (
  //                   privilege.subModule == child.name &&
  //                   privilege.subModule != 'Assets' &&
  //                   privilege.subModule != 'Sensors' &&
  //                   privilege.subModule != 'Remediation' &&
  //                   privilege.status == 'true' &&
  //                   privilege.permission != 'NONE')
  //               ) {

  //                 if (childItem.indexOf(child) !== -1) {
  //                   continue;
  //                 }
  //                 if (!(userRolesData == 'USER' && item.name == 'Administration' && child.name == 'Users')) {
  //                   childItem.push(child);
  //                 }

  //                 // Push 'Reports' page to 'Vendor Risk Mgmt' without checking any conditions
  //                 if (item.name == 'Vendor Risk Mgmt' && child.name == 'Reports') {
  //                   if (childItem.indexOf(child) === -1) {
  //                     childItem.push(child);
  //                   }
  //                 }

  //               }

  //               // WHEN ONLY ASM IS TRUE
  //               if (asm &&
  //                 (privilege.mainModule == 'ASM') &&
  //                 (item.name == 'ASM') &&
  //                 privilege.subModule == child.name &&
  //                 privilege.status == 'true' &&
  //                 privilege.permission != 'NONE'
  //               ) {

  //                 if (childItem.indexOf(child) !== -1) {
  //                   continue;
  //                 }
  //                 if (!(userRolesData == 'USER' && child.name == 'Users')) {
  //                   childItem.push(child);
  //                 }

  //               }

  //             }
  //           }
  //         }

  //         item.children = childItem;
  //         if (item.children?.length > 0) {
  //           this.filteredItems.push(item);
  //         }

  //       }
  //     }

  //     if (localStorage['UserRoles'] == 'NONE') {
  //       this.filteredItems.splice(0, 4);
  //     } else {
  //       if (userObjData.adminRole != 'NONE') {
  //         this.navItems = this.filteredItems;
  //         this.getAllURLs()
  //       }
  //     }
  //   } else {
  //     var url: any = [];
  //     url.push('/noPermission');
  //     localStorage.setItem('url', url);
  //   }

  //   this.hideBusinessUnit()

  //   localStorage.setItem('navItems', JSON.stringify(this.navItems));

  //   localStorage.setItem('isSmallNavBar', 'true');
  // }
  // getAllURLs() {
  //   this.hideBusinessUnit()
  //   var url: any = [];
  //   this.navItems.forEach((item: any) => {
  //     for (let subItem of item.children!) {
  //       if (subItem.url == '/rules') {
  //         url.push(item.url + '/grouprules');
  //       } else if (subItem.url == '/assets') {
  //         url.push(item.url + '/groups');
  //       } else if (subItem.url == '/vulnsByGroups') {
  //         url.push(item.url + '/vulnsByGroups/ID');
  //         url.push(item.url + '/findings');
  //       }
  //       url.push(item.url + subItem.url);
  //     }
  //   });
  //   localStorage.setItem('url', url);

  //   return url;
  // }
  // hideBusinessUnit() {
  //   // Hide Business Unit if this.singleBu=true
  //   if (this.singleBu) {
  //     var index = this.navItems.findIndex((mainModule: any) => mainModule.name == "Administration")

  //     if (index > -1) {
  //       var buIndex = this.navItems[index].children.findIndex((subModule: any) => subModule.name == "Business Units")

  //       if (buIndex > -1) {
  //         this.navItems[index].children.splice(buIndex, 1)
  //       }
  //     }
  //   }
  // }
  navigateToLogin() {
    this.router.navigate([
      environment.routePrefix
        ? '/' + environment.routePrefix + '/auth/login'
        : '/auth/login',
    ]); // this.change2FA.emit(false);
  }
}
