import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { delay } from 'rxjs';

@Component({
  selector: 'app-login-wrapper',
  standalone: true,
  imports: [],
  templateUrl: './login-wrapper.component.html',
  styleUrl: './login-wrapper.component.scss',
})
export class LoginWrapperComponent implements OnInit, OnDestroy {
  @Input() page?: any;
  private texts = [
    'Compliance Audits',
    'Vulnerability Management',
    'Risk Assessments',
    'Control Gap Assessments',
    'Third Party Risk Management',
  ];
  private currentTextIndex = 0;
  private currentText = '';
  private isDeleting = false;
  private typingSpeed = 150; // Adjust speed as needed
  private erasingSpeed = 80; // Adjust speed as needed
  private delayAfterWord = 1000; // Delay after typing complete word
  private delayBeforeNextWord = 500;
  private timeoutId: any;
  ngOnInit(): void {
    this.typeText();
  }

  private typeText() {
    const currentFullText = this.texts[this.currentTextIndex];

    if (this.isDeleting) {
      // Deleting text
      this.currentText = currentFullText.substring(
        0,
        this.currentText.length - 1
      );
    } else {
      // Typing text
      this.currentText = currentFullText.substring(
        0,
        this.currentText.length + 1
      );
    }

    // Update the DOM
    const typedTextElement = document.querySelector('.typed-text');
    if (typedTextElement) {
      typedTextElement.textContent = this.currentText;
    }

    // Calculate typing speed
    let typeSpeed = this.isDeleting ? this.erasingSpeed : this.typingSpeed;

    // Check if word is complete
    if (!this.isDeleting && this.currentText === currentFullText) {
      // Word is complete, wait before starting to erase
      typeSpeed = this.delayAfterWord;
      this.isDeleting = true;
    } else if (this.isDeleting && this.currentText === '') {
      // Word is fully erased, wait before starting next word
      this.isDeleting = false;
      this.currentTextIndex = (this.currentTextIndex + 1) % this.texts.length;
      typeSpeed = this.delayBeforeNextWord; // Add delay before starting next word
    }

    this.timeoutId = setTimeout(() => this.typeText(), typeSpeed);
  }

  ngOnDestroy(): void {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }
  }
}
